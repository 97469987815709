.bpChatBtn {
  position: fixed;
  bottom: 20px;
  left: 10px;
  z-index: 100000;
  background: #212b36;
  background: linear-gradient(90deg, #ffffffb4 0%, #f7f7f7c6 100%);
  border-radius: 50%;
  width: 34px;
  height: 34px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bpChatBtn img {
  width: auto;
}

.bpChatDisplyBox {
  width: 350px;
  height: 460px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 3px 1px rgb(196, 194, 194);
  position: fixed;
  z-index: 100000;
  bottom: 75px;
  left: 10px;
  display: none;
}

.bpChatDisplyBox:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 18px solid #fff;
  position: absolute;
  bottom: -12px;
  left: 12px;
  box-shadow: -1px 4px 45px -2px rgba(243, 243, 243, 0.22);
}

.bpChatDisplyBox.active {
  display: block;
}

.bpChatclose {
  width: 16px;
  cursor: pointer;
}

.bpChatDisplyHeaderBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bpChatfaceBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bpChatface {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #b7bdfd;
}

.bpChatnameBox {
  color: #fff;
  margin-left: 7px;
}

.bpChatnameBox p {
  margin-bottom: 0;
  line-height: 11px;
  font-size: 12px;
}

.bpChatDisplyBodyBox {
  padding: 10px 0;
}

.bpChatDisplySubBodyBox {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
}

.bpChatDisplyBodycomment {
  padding: 10px;
  margin: 10px 15px 0;
  border-radius: 6px;
  background-color: #e4f2ff;
  font-size: 14px;
  line-height: 20px;
}

.bpChatDisplySocialTittle {
  text-align: center;
  font-family: var(--fontBold);
  font-size: 15px;
  letter-spacing: 0;
}

.bpChatDisplySocial {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}

.bpChatDisplySocial li {
  list-style: none;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin: 2px;
  overflow: hidden;
}

.bpChatDisplySocial li img {
  width: 32px;
  height: 32px;
}

.bpChatDisplyconnctSocial {
  display: block;
}

.bpChatDisplyconnctSocial {
  display: block;
  padding: 10px 15px;
}

.bpChatDisplyconnctSocial li {
  background-color: #e4f2ff;
  border-radius: 5px;
  padding: 8px 10px;
  margin-bottom: 5px;
  font-size: 14px;
}

.bpChatDisplyconnctSocial li::marker {
  content: "";
}
.bpChatDisplyconnctSocial li a {
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bpChatDisplyconnctSocial img {
  width: 24px;
  margin-right: 10px;
}

.bpChatDisplyconnctSocial li a .connectBox {
  display: flex;
  align-items: center;
}

.bpChatInputField {
  width: calc(100% - 20px); /* Adjust width as needed */
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}
.bpChatBtnIcon {
  background: #f1f1f1;

  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.chatIcons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70px;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}
.chatIcons li {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222;
  cursor: "pointer";
  transition: 0.4s;
}
.chatIcons li:hover {
  background-color: #ccc;
}

.loginInput fieldset {
  border: 0;
}

.scrollChat ::-webkit-scrollbar {
  width: 10px;
}
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: var(--body-bg);
  font-family: Helvetica, sans-serif;
}

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 867px;
  margin: 25px 10px;
  height: calc(100% - 50px);
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
}

.msger-chat-right {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}
.msg-img {
  width: 40px;
  height: 40px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.msg-img-avatar {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  width: calc(100% + 50px);
  margin-left: 10px;
  padding: 15px;
  border-radius: 15px 15px 15px 0;
  background: var(--left-msg-bg);
}
.msg-bubble-right {
  background-color: #ff9800bf;
  width: calc(100% + 50px);
  margin-left: 50px;
  padding: 15px;
  border-radius: 15px 15px 0 15px;
  background: var(--left-msg-bg);
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.chatScrollBox {
  height: 255px;
  overflow-y: auto;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: var(--right-msg-bg);
  color: #fff;
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 13px;
}
.msger-inputarea * {
  padding: 4px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
.msger-input {
  flex: 1;
  border-radius: 25px;
  background: #f1f3f4;
  padding: 0 20px;
}
.msger-send-btn {
  border-radius: 50%;
  margin-left: 10px;
  background: #ff9800;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.msger-send-btn:hover {
  background: #ff9800bf;
}

.msger-chat {
  background-color: #fcfcfe;
}
.chat {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #579ffb;
}
.chat-header {
  display: flex;
  align-items: center;
  background-color: #075e54;
  color: #fff;
  padding: 10px;
  height: 60px;
}

.back-button {
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
}

.contact-name {
  flex-grow: 1;
  font-size: 18px;
  font-weight: bold;
}

.actions {
  /* Style additional actions here */
}
